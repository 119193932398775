.contact-section-inner {
    padding: 30px 20px;
    background: #f4f4f4;
}

.contact-info {
    display: flex;
    margin-bottom: 10px;
}

.contact-info-block p {
    text-align: left;
}

span.contact-icon {
    margin-right: 10px;
    color: #640C06;
    font-size: 25px;
}

.contact-text p {
    margin-bottom: 0px !important;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #0000008C;
    text-align: left;
}

.contact-section-inner {
    padding: 30px 20px;
    background: #f4f4f4;
    min-height: 600px;
    max-height: 100%;
}

.contact-info-block {
    margin-bottom: 30px;
}