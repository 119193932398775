.row {
    width: 100%;
}

.videos-content-block.container {
    display: flex;
    flex-wrap: wrap;
}

p.image-date {
    font-size: 16px;
    color: #00000080;
    font-weight: 500;
}

.image-description {
    margin-bottom: 15px;
    margin-top: 15px;
}

.videos-image-block {
    cursor: pointer;
}

.videos-image-block img {
    width: 100%;
    height: auto !important;
}

.pagination-content-inner {
    display: flex;
    width: 100%;
    margin-bottom: 30px;
}

.pagination-content-inner ul {
    display: flex;
    width: 100%;
    padding-left: 0px;
    justify-content: center;
    overflow-x: auto;
    margin-top: 10px;
    padding: 10px 0px;
}

.pagination-content-inner ul li {
    list-style: none;
    width: 50px;
    height: 50px;
    background-color: #D9D9D9;
    margin: 0px 10px;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
}


.pagination-content-inner ul li a {
    font-size: 20px;
    font-weight: bold;
    color: #000;
    padding-top: 13px;
}

.pagination-content-inner ul li a:hover {
    text-decoration: none;
}

.page-active {
    background-color: #640C06 !important;
}

.page-active a {
    color: #fff !important;
}

span.next-page-icon {
    font-size: 30px;
    position: relative;
    bottom: 10px;
}

.image-description h5 {
    font-size: 20px;
    color: #640C06;
    font-weight: bold;
}

._3AWTh img {
    width: 800px;
}

._2pWTS {
    left: 49% !important;
    top: 80% !important;
    transform: translate(-80%, -47%);
}

._18xMp {
    right: 45% !important;
    top: 80% !important;
    transform: translate(-80%, -47%);
}

button._3bkri {
    background-color: #e0e0e0 !important;
    color: #000 !important;
}

._3AWTh::before {
    background-color: rgba(0, 0, 0, 0.6) !important;
}


.more {
    display: block;
}


.App {
    font-family: sans-serif;
    text-align: center;
}

.App button {
    padding: 0px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    outline: none;
    /* background: crimson; */
    cursor: pointer;
}

#portal .container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
}

.videos-image-block img {
    height: auto !important;
}

.videos-image-block {
    height: 200px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

button.videos-button {
    width: 100%;
}

/* .container .modal {
    position: absolute;
    left: 16%;

    z-index: 999999999;
    width: 900px;
    top: 250px;
    height: 500px;
    background: #cccc;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    animation: animate 0.3s;

} */

.container .modal {
    position: absolute;
    z-index: 999999999;
    width: 900px;
    /* margin-left: 100px; */
    top: 50%;
    height: 500px;
    background: #cccc;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    animation: animate 0.3s;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    bottom: 50%;
}

.button-model-block {
    position: relative;
    bottom: 40px;
    /* left: 44%;
    bottom: 300px; */
}

/* .videos-container .model-photo.button-model-block button {
    position: relative !important;
} */

.videos-container .video-model-block-button button {
    position: relative !important;
    margin: 10px 15px;
    top: 10px;
    font-size: 20px;
}

.video-model-block-button {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
}

/* .model-photo.button-model-block {
    bottom: 300px !important;
} */


button.left-angle-button {
    left: 0px;
    font-size: 20px;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;

}

button.right-angle-button {
    left: 30px;
    font-size: 20px;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.modal-image-block {
    position: relative;
}

.modal button {
    position: absolute;
    top: 7px;
    right: 7px;
    padding: 8px 11px;
    font-weight: bold;
    border: none;
    outline: none;
    border-radius: 2px;
    cursor: pointer;
    background-color: #fff;
    color: #000 !important;
    color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

@keyframes animate {
    from {
        transform: scale(0.5);
    }

    to {
        transform: scale(1);
    }
}

.video-react-player {
    width: 950px !important;
    height: 550px !important;
}

.margin {
    display: inline;
    width: 100%;
    height: auto;
}

button.button-click {
    width: 100%;
}


.box {
    width: 100%;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.2);
    padding: 35px;
    border: 2px solid #fff;
    border-radius: 20px/50px;
    background-clip: padding-box;
    text-align: center;
}

/* .button {
    font-size: 1em;
    padding: 10px;
    color: #fff;
    border: 2px solid #06D85F;
    border-radius: 20px/50px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease-out;
} */

/* .button:hover {
    background: #06D85F;
} */

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
    z-index: 4;
}

.overlay:target {
    visibility: visible;
    opacity: 1;
}

.popup {
    margin: 70px auto;
    background: #fff;
    border-radius: 5px;
    width: 900px;
    position: relative;
    transition: all 0.2s;
}

.popup h2 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
}

.popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
    background-color: #fff;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    padding-top: 7px;
    border: 1px solid #000;
}

.popup .content {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.video-back-button a {
    background: #640c06;
    padding: 12px 25px;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
}

.video-back-button {
    display: inline-block;
    width: 100%;
    text-align: right;
    position: relative;
    right: 40px;
    margin-top: 15px;
}

.next-prev-icon-block {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: -90px;
}

.modal-content-inner img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 992px) {
    .box {
        width: 100%;
        padding: 20px;
    }

    .popup {
        width: 80%;
        height: 450px;
    }

    .video-react-player {
        width: 80% !important;
        height: 450px !important;
    }
}