.row {
    margin: 0px;
}

.full-page-block {
    font-family: 'PT Sans', sans-serif !important;
}

/* .logo-block img {
    width: 550px;
    max-width: 100%;
}

.header-block {
    padding: 30px;
}

.menu-block ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.menu-block ul li {
    list-style: none;
    margin: 0px 15px;
}

.menu-block ul li a {
    color: #000;
    font-size: 17px;

}

.menu-block ul li a:hover {
    text-decoration: none;
    color: #640C06;
    font-weight: bold;
    transition: 0.1s;
}

.nav-section {
    margin-top: 20px;
} */

.slider-images img {
    width: 100%;
}

/* 
.slider-image-block {
    height: 650px;
}

.slider-image-block img {
    height: 100%;
} */


.slider-content-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 100%;
}

.slider-content-inner h1 {
    font-size: 46px;
    font-weight: bold;
    color: #fff;
}

.slider-content-inner p {
    font-size: 16px;
    color: #fff;
}

.slider-section-inner {
    position: relative;
}

.slider-button-block a {
    padding: 16px 20px;
    background: #640C06;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
}

.slider-button-block a:hover {
    background: #000;
    text-decoration: none;
}

.slider-button-block {
    padding-top: 25px;
}

/* ======================================slider css end============================== */

/* ======================================about us css start============================== */

.about-us-section-block {
    padding: 50px 20px;
}

.about-us-inner h2 {
    font-size: 34px;
    font-weight: bold;
    color: #630b09;
    margin-bottom: 20px;
}

.about-us-info-text p {
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;

}

.read-more-button-block a {
    padding: 16px 20px;
    background: #640C06;
    color: #fff;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
}

.read-more-button-block a:hover {
    background: #000;
    text-decoration: none;
}

.read-more-button-block {
    display: inline-block;
    margin-top: 20px;
}

/* ======================================about us css end============================== */

/* ======================================news section css start============================== */

.news-section-inner h2 {
    font-size: 34px;
    font-weight: bold;
    color: #630b09;
    margin-bottom: 30px;
}

.news-block-inner-block {
    background-color: #E7E7E7;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.news-image-block img {
    width: 100%;
}

.news-number {
    font-size: 22px;
    font-weight: bold;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #640C06;
}

.news-info-text p {
    text-align: left;
    font-size: 16px;
}

.news-learn-more a {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    text-decoration: underline;
}

.news-learn-more {
    width: 100%;
    text-align: left;
}

.more-news-button-block {
    width: 100%;
    margin: 40px 0px;
    display: inline-block;
}

.more-news-button-block a {
    padding: 16px 20px;
    background: #640C06;
    color: #fff;
    border-radius: 8px;
    font-size: 16px;
    margin-top: 20px;
}

.more-news-button-block a:hover {
    background: #000;
    text-decoration: none;
}

.news-heading h2 {
    font-size: 34px;
    font-weight: bold;
    color: #630b09;
    margin: 30px 0px;
}

.news-block-inner {
    margin-bottom: 25px;
}

.news-image-block {
    height: 230px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

/* ======================================news section css end============================== */

/* ======================================partnerships css start============================== */
.partnerships-heading h2 {
    font-size: 34px;
    font-weight: bold;
    color: #630b09;
    margin: 30px 0px;
}

.partners-inner-block img {
    width: 100%;
}

.partners-inner-block img {
    height: 100%;
}

.partners-inner-block {
    margin-bottom: 20px;
    box-shadow: 1px 1px 9px 1px #10101021;
    height: 140px;
    margin-bottom: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}


/* ======================================partnerships css end============================== */

/* ===========================================responsive css strat ============================= */

@media screen and (max-width: 768px) {
    .slider-content-inner h1 {
        font-size: 34px;
    }

    .slider-content-inner p {
        padding: 20px;
        font-size: 14px;
    }

    .about-us-info-text p {
        text-align : justify; 
        text-justify: inner-word;
    }
}