.herbal-section-inner {
    padding: 30px 20px;
    background: #f4f4f4;
}

.herbal-section-inner h1 {
    font-size: 40px;
    font-weight: bold;
    color: #620a0c;
    margin-bottom: 20px;
}

.herbal-section-inner h2 {
    font-size: 32px;
    font-weight: bold;
    color: #620a0c;
}

.info-heading-text p {
    font-size: 24px !important;
    font-weight: bold !important;
    color: #620a0c !important;
    text-align: left;
}

.herbal-back-button {
    display: flex;
    justify-content: right;
}

.herbal-back-button a {
    background: #620a0c;
    padding: 8px 25px;
    border-radius: 5px;
    color: #fff;
}

.herbal-back-button a:hover {
    text-decoration: none;
    background-color: #000;
    color: #fff;
}

.herbal-info-text h2 {
    font-weight: 500 !important;
    font-size: 28px;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.herbal-info-text p {
    text-align: left;
    font-size: 17px;
    margin-bottom: 5px;
    font-weight: 500;
    display: flex;
    justify-content: center;
}

.herbal-info-text img {
    height: 300px;
}

.herbal-info-text h2 {
    text-align: left;
    margin: 10px 0px;
}

.info-heading-text {
    margin: 20px 0px;
}

.herbal-info-text a {
    margin-bottom: 10px;
    display: inline-block;
}

.herbal-info-text ul {
    padding-left: 15px;
}

.plantHolder{
    padding: 0;
    margin: 0;
    text-align: left;
}

.plantItem{
    display:inline-block;
    padding-right: 10px;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .herbal-info-text img {
        width: 50% !important;
    }

    .herbal-info-text p {
        font-size: 16px;
        display: inherit;
    }
}

@media screen and (max-width: 768px) {
    .herbal-info-text p {
        font-size: 16px;
        display: inherit;
    }

    .herbal-section-inner h2 {
        font-size: 20px;
    }

    .herbal-section-inner h1 {
        font-size: 28px;
    }

    .herbal-info-text img {
        width: 100% !important;
    }
}

.herbal-info-text img {
    width: 33%;
    float: left;
    padding: 10px;
}

.herbal-info-text {
    display: flex;
}

.herbal-back-button a {
    background: #640c06;
    padding: 8px 25px;
    border-radius: 5px;
    color: #fff;
}

.plantModal{
    overflow: hidden;
}

.plantInfoTitle{
    text-align: left;
    font-weight: bold;
    color: #640c06;
}

.circlePlant{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 5px;
}

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .plantIframe{
        width: 310px;
        min-height: 800px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {    
    .plantIframe{
        width: 780px;
        height: 550px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .plantIframe{
        width: 780px;
        height: 550px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .plantIframe{
        width: 780px;
        height: 550px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .plantIframe{
        width: 780px;
        height: 550px;
    }
    .react-responsive-modal-modal{
        overflow: hidden;
    }
}