.footer-section-inner {
    background-color: #630b09;
    padding: 30px 20px;
}

.footer-text-info p {
    color: #fff;
    font-size: 18px;
    margin-bottom: 5px;
    font-style: italic;
}

.footer-text-info {
    margin-top: 20px;
}

.footer-logo-block img {
    width: 550px;
    max-width: 100%;
}


@media screen and (max-width: 768px) {
    .footer-text-info p {
        font-size: 15px;
    }
}