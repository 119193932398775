.loader-block {
    position: relative;
    width: 100%;
}

.loder-block {
    width: 100%;
    position: absolute;
    left: 50%;
    z-index: 9999;
    top: 45%;
    /* background-color: #000; */
}

.gallery-section {
    position: relative;
    overflow: hidden !important;
}