.about-us-content-text p {
    text-align: left;
    font-size: 16px;
    margin-bottom: 5px;
}

.about-us-info {
    margin-bottom: 20px !important;
    display: inline-block;
    width: 100%;
}

.about-us-section {
    padding: 50px 20px;
    background: #f4f4f4;
    min-height: 600px;
    max-height: 100%;
}

.about-us-inner-block h2 {
    font-size: 34px;
    font-weight: bold;
    color: #630b09;
    margin-bottom: 40px;
}

.about-us-content-text ul {
    padding-left: 15px;
}