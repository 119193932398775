.chart-arrow-up{
  margin-bottom: 2px;
}
.chart-arrow-up2{
  margin-bottom: 5px;
}
.top-line{
  margin-top: 10px;
}
.top-block{
  margin-bottom: -15px;
}
.chart-color {
  color: rgb(207, 207, 207);
}
.chart-line{
  background: rgb(207, 207, 207);
  height: 2.5px;
  margin-bottom: -3px;
}
.chart-line2{
  background: rgb(207, 207, 207);
  height: 2.5px;
  margin-bottom: -7px;
}
.start-line{
  width: 50%;
  margin-left: 50%;

}
.middle-line{
  width: 130%;
  margin-left: -30px;
}
.middle-line2{
  width: 180%;
  margin-left: -50%;
}
.end-line{
  width: 50%;

}
.member-section-inner {
    padding: 30px 20px;
    background-color: #f4f4f4;
    max-width: 100%;
    width: 1800px;
    overflow-x: auto;
    -webkit-overflow-x: auto;
}

.member-section-inner h2 {
    font-size: 34px;
    font-weight: bold;
    color: #620a0c;
}

span.members-info-details p {
    width: 100%;
    margin-bottom: 0px;
}

.members-info-details {
    background-color: #fff;
    display: block;
    padding: 20px 10px;
    width: 250px;
    border-radius: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    min-height: 100px;
    max-height: 100%;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.member-cetagory-info p {
    width: 100%;
    margin-bottom: 0px;
}

.member-cetagory-info {
    margin: 0 auto;
    background-color: #fff;
    display: block;
    padding: 20px 10px;
    width: 180px;
    border-radius: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    min-height: 100px;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.member-info {
    background-color: #fff;
    display: block;
    padding: 20px 10px;
    width: 235px;
    border-radius: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    height: 100px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}

.member-cetagory-info h3 {
    font-size: 17px;
    font-weight: bold;
    color: #640C06;
}

.react-tabs__tab-panel--selected {
    display: block;
    margin-top: -15px;
}

span.members-info-details h4 {
    font-size: 17px;
    font-weight: bold;
    color: #640C06;
    width: 100%;
}

.members-content {
    margin-top: 50px;
}

.members-info-block {
    display: flex;
    justify-content: center;
}

/* .more-diesplay {
    display: inline;
    width: 100%;
    position: relative
} */

.member-tab-info {
    display: flex;
    justify-content: center;
}

.direction-path-image img {
    width: 600px;
}

.react-tabs__tab-list {
    border: none !important;
}

.react-tabs__tab--selected {
    border: none !important;
    background: transparent !important;
}

.member-details-row {
    width: 235px;
    display: flex;
    flex-direction: column;
    margin: 0px 10px;
}

.member-name p {
    margin-bottom: 0px;
    font-weight: 500;
}

.member-name {
    padding-left: 15px;
    color: #630b09;
    font-size: 16px;
    font-weight: bold;
}

.member-inner-content {
    display: flex;
    justify-content: space-between;
    margin-top: -6px;
}

.tab-full-content-block {
    max-width: 1870px;
    width: 100%;
    margin: 0px auto;
    /* overflow-x: scroll; */
}

.tab-full-content-block-1 {
    max-width: 1270px;
    width: 100%;
    margin: 0px auto;
    /* overflow-x: scroll; */
}

.tab-full-content-block-2 {
    max-width: 1000px;
    width: 100%;
    margin: 0px auto;
    /* overflow-x: scroll; */
}

ul.react-tabs__tab-list {
    display: flex;
    justify-content: center;
}

.contact-section-inner h2 {
    font-size: 34px;
    font-weight: bold;
    color: #620a0c;
}

.contact-details {
    margin-top: 40px;
}

.map-image {
    width: 100%;
    float: left;
}

/* .map-image{
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    top: 0px;
    
} */

.react-tabs__tab {
    border: 0px !important;
    height: max-content;
}

@media screen and (min-width: 1800px) {
    .member-section-inner {
        min-width: 100%;
        width: 100%;
    }
}