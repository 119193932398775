.news-block-post {
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
}

.news-details-info {
    padding-left: 20px;
}

.news-section-inner {
    background: #f4f4f4;
    padding: 30px 0px;
}

.pagination-content-inner {
    display: flex;
    width: 100%;
}

.pagination-content-inner ul {
    display: flex;
    width: 100%;
    padding-left: 0px;
    justify-content: center;
    padding: 10px 0px;
}

.pagination-content-inner ul li {
    list-style: none;
    width: 50px;
    height: 50px;
    background-color: #D9D9D9;
    margin: 0px 10px;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
}

.pagination-content-inner ul li a {
    font-size: 20px;
    font-weight: bold;
    color: #000;
    background-color: transparent;
    border: 0px;
    width: 50px;
    height: 50px;
    padding-top: 10px;
}

li.page-item.disabled .page-link {
    border-radius: 50%;
    position: relative;
    left: 1px;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #630b09;
    border-color: #630b09;
    border-radius: 50%;
}

.pagination-content-inner ul li a:hover {
    text-decoration: none;
    border-radius: 50%;
    position: relative;
    left: 1px;
}

.page-item:last-child .page-link {
    border-radius: 50% !important;
}

.page-active {
    background-color: #640C06 !important;
}

.page-active a {
    color: #fff !important;
}

.news-image-block-post img {
    width: 100%;
}

.news-image-block-post {
    width: 300px;
    min-width: 300px;
    max-width: 100%;
    padding-top: 20px;
}

span.next-page-icon {
    font-size: 30px;
    position: relative;
    bottom: 3px;
}

.news-section-inner h1 {
    font-size: 34px;
    font-weight: bold;
    color: #630b09;
    margin-bottom: 30px;
}

.news-deatils-large-image img {
    width: 100%;
    /* height: 550px; */
    max-height: 100%;
}

@media screen and (max-width: 768px) {
    .news-deatils-inner p {
        font-size: 16px;
    }

    .news-image-block-post {
        width: 100%;
        min-width: 100%;
    }

    .news-block-post {
        flex-wrap: wrap;
    }

    .news-details-info {
        padding-left: 0px;
        margin-top: 10px;

    }

    .news-image-block-post img {
        height: auto;
    }
}

@media screen and (max-width: 1024px) {
    .container.custom-container-news {
        width: 95% !important;
        max-width: 95%;
    }
}