.news-deatils-inner h1 {
    font-size: 34px;
    font-weight: bold;
    color: #630b09;
    margin: 30px 0px;
}

p.info-red {
    font-size: 24px;
    text-align: left;
    margin-top: 15px;
    font-weight: bold;
    color: #620a0c;
}

.news-deatils-inner p {
    color: #00000080;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
}

.news-details-images img {
    width: 100%;
    margin-bottom: 10px;
}

.news-deatils-inner {
    padding: 30px 20px;
    background: #f4f4f4;
}

.news-details-images-block {
    margin: 30px 0px;
    border: 2px dashed #9747FF;
    padding: 20px 10px;
    border-radius: 10px;
}

.news-deatils-large-image {
    width: 100%;
    height: 600px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

}

.back-cutton {
    display: flex;
    justify-content: right;
}

.back-cutton a {
    background: #620a0c;
    padding: 8px 25px;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
}

.back-cutton a:hover {
    text-decoration: none;
    background-color: #000;
    color: #fff;
}

.news-details-info a:hover {
    text-decoration: none;
}

.articleContent img{
    padding: 10px;
    margin: 10px;
    border: 1px solid #9c9c9c;
}

button.newsD-button {
    width: 30%;
    padding: 10px !important;
    border: 1px solid #e0e0e0 !important;
    margin: 5px;
}

@media screen and (max-width: 768px) {
    .news-deatils-large-image {
        height: 400px;
    }
}