.gallery-section {
    padding: 30px 20px;
    background: #f4f4f4;
}

.gallery-large-image {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    aspect-ratio: 16/9;
}

.title {
    font-size: 34px;
    font-weight: bold;
    color: #620a0c;
    padding: 30px 0;
}

section.gallery-section {
    padding: 0px 120px;
}

.gallery-details {
    margin-top: 15px;
}

.gallery-pagination-content-inner {
    display: flex;
    width: 100%;
}

.gallery-pagination-content-inner ul {
    display: flex;
    width: 100%;
    padding-left: 0px;
    justify-content: center;
    padding: 10px 0px;
    margin-top: 30px;
}

.gallery-pagination-content-inner ul li {
    list-style: none;
    width: 50px;
    height: 50px;
    background-color: #D9D9D9;
    margin: 0px 10px;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
}

.gallery-pagination-content-inner ul li a {
    font-size: 20px;
    font-weight: bold;
    color: #000;
    background-color: transparent;
    border: 0px;
    width: 50px;
    height: 50px;
    padding-top: 10px;
}

.gallery-section {
    position: relative;
    overflow: hidden !important;
    width: 100% !important;
}

@media screen and (max-width: 992px) {

    .gallery-section {
        padding: 20px !important;
    }
}