.logo-block img {
    width: 550px;
    max-width: 100%;
}

.language-info-inner img {
    width: 25px;
    margin-right: 5px;
}

.header-block {
    padding: 30px 20px;
}

.language-info {
    display: flex;
    float: right;
    padding-top: 15px;
}

.menu-block ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-left: 0px;
}

.menu-block ul li {
    list-style: none;
    margin: 0px 15px;
}

.menu-block ul li a {
    color: #000;
    font-size: 16px;
}

.menu-block ul li a:hover {
    text-decoration: none;
    color: #640C06;
    font-weight: bold;
    transition: 0.1s;
}

.nav-section {
    margin-top: 20px;
}

.menu {
    position: fixed;
    top: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 50%;
    transition: transform 250ms ease;
    cursor: pointer;
    z-index: 200;
}

.menu span {
    position: relative;
    display: block;
    width: 50%;
    height: 2px;
    background-color: #444;
    float: left;
    transform-origin: center center;
    transition: transform 250ms ease;
    z-index: 200;
}

.menu span:nth-of-type(1) {
    transform: translateY(-5px);
}

.menu span:nth-of-type(3) {
    transform: translateY(5px);
}

#menu {
    display: none;
}

#menu:checked~.menu {
    background-color: transparent;
    transform: rotate(360deg);
    transition: transform 250ms ease;
}

#menu:checked~.menu span {
    background-color: white;
    transition: transform 250ms ease;
}

#menu:checked~.menu span:nth-of-type(1) {
    transform: translateY(1px) rotate(45deg);
}

#menu:checked~.menu span:nth-of-type(2) {
    display: none;
}

#menu:checked~.menu span:nth-of-type(3) {
    transform: translateY(-1px) rotate(-45deg);
}

#menu:checked~.nav {
    left: 0px;
    transition: left 500ms ease;
}

#menu:checked~main {
    transform: translateX(250px);
    transition: transform 500ms ease;
}

.nav {
    position: fixed;
    top: 0;
    left: -210px;
    width: 300px;
    height: 100vh;
    margin: 0;
    padding: 0;
    background-color: #444;
    transition: left 500ms ease;
    z-index: 0;
}

.nav ul {
    position: relative;
    list-style-type: none;
    margin: 100px 0;
    padding: 0;
}

.nav ul li {
    position: relative;
    display: block;
    border-bottom: 5px solid white;
}

.nav ul li a {
    position: relative;
    display: block;
    margin: 0;
    padding: 15px 20px;
    color: white;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
}

.nav ul li a:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    transition: width 250ms ease;
    z-index: -1;
}

.nav ul li a:hover {
    color: #444;
}

.nav ul li a:hover:before {
    width: 100%;
    height: 100%;
    background-color: white;
    transition: width 250ms ease;
}

.container {
    /* width: 75%; */
    margin: 0 auto;
}

main {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    transform: translateX(0px);
    transition: transform 500ms ease;
    z-index: 0;
}

main header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 300px;
    background-color: whitesmoke;
}

main header h1 {
    color: #ccc;
    z-index: 3;
}

main section {
    position: relative;
    display: block;
    margin: 0;
    padding: 50px 0;
    background-color: white;
    z-index: 100;
}

main section p {
    margin-bottom: 30px;
}

main .img {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 300px;
    background-color: #444;
}

main .img h1 {
    color: rgba(255, 255, 255, 0.2);
}

main footer {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 20px 20px 20px;
    background-color: #444;
}

main footer::after {
    clear: both;
    content: "";
    display: block;
}

main footer .logo {
    font-size: 28px;
    color: whitesmoke;
    line-height: 0;
    margin-bottom: 8px;
    margin-right: 4px;
}

@media screen and (min-width: 1024px) {
    main footer .logo {
        float: left;
        margin-bottom: 0;
    }
}

main footer ul {
    line-height: 1.5;
    margin: 0 0 16px 0;
    padding: 0;
}

main footer ul::after {
    clear: both;
    content: "";
    display: block;
}

@media screen and (min-width: 1024px) {
    main footer ul {
        float: left;
        line-height: 1.8;
        margin-bottom: 0;
        margin-left: 16px;
    }

    main footer ul::after {
        clear: both;
        content: "";
        display: block;
    }
}

main footer ul li {
    list-style: none;
    padding-right: 16px;
}

@media screen and (min-width: 1024px) {
    main footer ul li {
        display: inline;
        text-align: left;
    }
}

main footer ul li a {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
}

main footer ul li a:focus,
main footer ul li a:hover {
    color: white;
}

@media screen and (min-width: 1024px) {
    main footer .secondary-links {
        float: right;
    }
}

main footer .secondary-links li a {
    font-size: 12px;
    font-weight: 100 !important;
}

main footer .secondary-links .social {
    margin: 1em 0 0 0;
}

@media screen and (min-width: 1024px) {
    main footer .secondary-links .social {
        float: right;
        margin-top: 0;
    }
}

main footer .secondary-links .social li {
    float: left;
    font-size: 1em;
    line-height: 0;
    margin: 0;
    padding-right: 0.7em;
}

main footer .secondary-links .social li:last-child {
    padding-right: 0;
}

main footer .secondary-links .social li>a {
    position: relative;
    display: block;
    width: 30px;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    color: #444;
    text-align: center;
    line-height: 30px;
}

main footer .secondary-links .social li>a:hover {
    background-color: white;
}




/* ==========================================mobile menu =============================================== */

.menu {
    position: absolute;
    top: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 50%;
    transition: transform 250ms ease;
    cursor: pointer;
    z-index: 200;
}

.menu span {
    position: relative;
    display: block;
    width: 50%;
    height: 2px;
    background-color: #444;
    float: left;
    transform-origin: center center;
    transition: transform 250ms ease;
    z-index: 200;
}

.menu span:nth-of-type(1) {
    transform: translateY(-5px);
}

.menu span:nth-of-type(3) {
    transform: translateY(5px);
}

#menu {
    display: none;
}

#menu:checked~.menu {
    background-color: transparent;
    transform: rotate(360deg);
    transition: transform 250ms ease;
    position: fixed;
}

#menu:checked~.menu span {
    background-color: white;
    transition: transform 250ms ease;
}

#menu:checked~.menu span:nth-of-type(1) {
    transform: translateY(1px) rotate(45deg);
}

#menu:checked~.menu span:nth-of-type(2) {
    display: none;
}

#menu:checked~.menu span:nth-of-type(3) {
    transform: translateY(-1px) rotate(-45deg);
}

#menu:checked~.nav {
    left: 0px;
    transition: left 500ms ease;
    z-index: 9;
}

span.language-info {
    float: right;
    padding-top: 35px;
    /* padding-right: 23px; */
    position: relative;
    right: 20px
}

.language-info a {
    border-right: 2px solid #630b09;
    padding: 0px 10px;
    color: #000;
}

.language-info a:hover {
    text-decoration: none;
}

#menu:checked~main {
    transform: translateX(250px);
    transition: transform 500ms ease;
}

.nav {
    position: fixed;
    top: 0;
    left: -340px;
    width: 300px;
    height: 100vh;
    margin: 0;
    padding: 0;
    background-color: #630b09;
    transition: left 500ms ease;
    z-index: 2;

}

.nav ul {
    position: relative;
    list-style-type: none;
    margin: 100px 0;
    padding: 0;
    width: 100%;
}

.nav ul li {
    position: relative;
    display: block;
    border-bottom: 0px;
}

.nav ul li a {
    position: relative;
    display: block;
    margin: 0;
    padding: 15px 20px;
    color: white;
    font-size: 14px;
    /* font-weight: bold; */
    text-decoration: none;
    /* text-transform: uppercase; */
    cursor: pointer;
}

.nav ul li a:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    transition: width 250ms ease;
    z-index: -1;
}

.nav ul li a:hover {
    color: #444;
}

.nav ul li a:hover:before {
    width: 100%;
    height: 100%;
    background-color: white;
    transition: width 250ms ease;
}

.container {
    /* width: 75%; */
    margin: 0 auto;
}

main {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    transform: translateX(0px);
    transition: transform 500ms ease;
    z-index: 0;
}

main header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 300px;
    background-color: whitesmoke;
}

main header h1 {
    color: #ccc;
    z-index: 3;
}

main section {
    position: relative;
    display: block;
    margin: 0;
    padding: 50px 0;
    background-color: white;
    z-index: 100;
}

main section p {
    margin-bottom: 30px;
}

main .img {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 300px;
    background-color: #444;
}

main .img h1 {
    color: rgba(255, 255, 255, 0.2);
}

main footer {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 20px 20px 20px;
    background-color: #444;
}

main footer::after {
    clear: both;
    content: "";
    display: block;
}

main footer .logo {
    font-size: 28px;
    color: whitesmoke;
    line-height: 0;
    margin-bottom: 8px;
    margin-right: 4px;
}

@media screen and (min-width: 1024px) {
    main footer .logo {
        float: left;
        margin-bottom: 0;
    }
}

main footer ul {
    line-height: 1.5;
    margin: 0 0 16px 0;
    padding: 0;
}

main footer ul::after {
    clear: both;
    content: "";
    display: block;
}

@media screen and (min-width: 1024px) {
    main footer ul {
        float: left;
        line-height: 1.8;
        margin-bottom: 0;
        margin-left: 16px;
    }

    main footer ul::after {
        clear: both;
        content: "";
        display: block;
    }
}

main footer ul li {
    list-style: none;
    padding-right: 16px;
}

@media screen and (min-width: 1024px) {
    main footer ul li {
        display: inline;
        text-align: left;
    }

}

main footer ul li a {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
}

main footer ul li a:focus,
main footer ul li a:hover {
    color: white;
}

@media screen and (min-width: 1024px) {
    main footer .secondary-links {
        float: right;
    }
}

main footer .secondary-links li a {
    font-size: 12px;
    font-weight: 100 !important;
}

main footer .secondary-links .social {
    margin: 1em 0 0 0;
}

@media screen and (min-width: 1024px) {
    main footer .secondary-links .social {
        float: right;
        margin-top: 0;
    }
}

main footer .secondary-links .social li {
    float: left;
    font-size: 1em;
    line-height: 0;
    margin: 0;
    padding-right: 0.7em;
}

main footer .secondary-links .social li:last-child {
    padding-right: 0;
}

main footer .secondary-links .social li>a {
    position: relative;
    display: block;
    width: 30px;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    color: #444;
    text-align: center;
    line-height: 30px;
}

main footer .secondary-links .social li>a:hover {
    background-color: white;
}

.mobile-menu-section {
    display: none;
}

.language-info-inner p {
    float: left;
    cursor: pointer;
}

/* ==================================================responsive css======================================== */
@media screen and (max-width: 992px) {
    .nav-section {

        display: none;
    }

    .mobile-menu-section {
        display: block;
    }

    .header-block {
        padding: 10px 20px;
    }

    .logo-block img {
        width: 200px;
        left: 25px;
    }

    .logo-img {
        position: relative;
        top: 7px;
        margin: 0px auto;
    }

    .language-info-inner img {
        width: 25px;
        margin-right: 5px;
    }

    .menu {
        top: 10px;
    }
}


@media screen and (max-width: 400px) {
    .logo-block img {
        width: 150px;
        top: 12px;
    }

    .header-block {
        padding: 20px 20px;
    }

    .logo-block img {
        top: 3px;
    }

    .language-info {
        padding-top: 4px;
    }
}


@media screen and (min-width: 992px) and (max-width: 1200px) {

    .menu-block ul li {
        margin: 0px 7px;
    }
}