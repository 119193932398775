.achievement-section-inner {
    padding: 30px 20px;
    background: #f4f4f4;
}

.achievement-section-inner h2 {
    font-size: 32px;
    font-weight: bold;
    color: #620a0c;
    margin-bottom: 20px;
}

.achievement-info-text h2 {
    font-size: 32px;
    font-weight: bold;
    color: #620a0c;
}

.info-heading-text p {
    font-size: 24px !important;
    font-weight: bold !important;
    color: #620a0c !important;
    text-align: left;
}

.achievement-info-text h2 {
    font-weight: 500 !important;
    font-size: 28px;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.achievement-info-text p {
    text-align: left;
    font-size: 16px;
    margin-bottom: 5px;
}

.achievement-info-text h2 {
    text-align: left;
    margin: 10px 0px;
}

.info-heading-text {
    margin: 20px 0px;
}

.achievement-info-text ul {
    padding-left: 15px;
}

@media screen and (max-width: 768px) {
    .achievement-info-text p {
        font-size: 16px;
    }

    .achievement-section-inner h2 {
        font-size: 20px;
    }

    .achievement-section-inner h1 {
        font-size: 28px;
    }
}